<template>
  <div class="ai-box">
    <el-container v-loading="showLoading"
                  element-loading-text="加载中..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-header style="padding: 0;">
        <div class="ai-header">
          <div class="h5-show-btn">
            <el-button type="text" size="mini" icon="el-icon-arrow-left" @click="goBack">返回查询</el-button>
            <el-button type="primary" size="mini" icon="el-icon-upload2" @click="lookItem">导出</el-button>
          </div>
          <div class="logo-img" @click="goHome">
            <img src="@/assets/images/logo_h.png" alt="蚂蚁写作">
            <span>- AI无限改稿</span>
          </div>
          <div class="ai-header-title">
            <span>标题：</span>
            <h1>{{ title }}</h1>
          </div>
          <div class="today-num">
            <el-tag type="danger" size="mini">每日免费改稿共计 {{ todayNumber.todayTotalNum }} 次</el-tag>
            <el-tag type="success" size="mini">今日已使用 {{ todayNumber.todayUseNum }} 次</el-tag>
          </div>
          <div class="ai-header-btn pc-show-btn">
            <el-button size="mini" icon="el-icon-arrow-left" @click="goBack">返回查询</el-button>
            <el-button type="primary" size="mini" icon="el-icon-upload2" @click="lookItem">导出</el-button>
          </div>
        </div>
      </el-header>
      <el-main class="ai-main">
        <div class="ai-main-box">
          <div class="ai-directory">
            <div class="ck-btn">
              <span>目<br/>录</span>
              <div class="ck-cont show-ck" v-if="contentJson">
                <div class="mu-lu-l1" v-for="(item,index) in contentJson" :key="item.firstTitle + index">
                  <h2 v-if="getFirstTitle(item)">{{ item.firstTitle }}</h2>
                  <div class="mu-lu-l2">
                    <h3 v-if="getSecondTitle(item)" @click="scrollToAnchor('section'+'-'+index)">{{ item.secondTitle }}</h3>
                    <div class="mu-lu-l3">
                      <h4 v-if="getThreeTitle(item)" @click="scrollToAnchor('section'+'-'+index)">{{ item.threeTitle }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ai-cont" v-if="contentJson">
            <div class="ai-cont-list" v-for="(item,index) in contentJson" :id="'section'+'-'+index" :key="'section'+'-'+index">
              <h2 v-if="getFirstTitle(item)">{{ item.firstTitle }}</h2>
              <h3 v-if="getSecondTitle(item)">{{ item.secondTitle }}</h3>
              <h4 v-if="getThreeTitle(item)">{{ item.threeTitle }}</h4>
              <Modifier :item="item.content"
                        :indexNum="index"
                        @modifyContent="modifyContent" @getTodayNumber="getTodayNumber"/>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Modifier from "./components/Modifier.vue"
import RewriteApi from '@/api/rewrite'

export default {
  components: {
    Modifier
  },
  data() {
    return {
      title: '',
      orderId: '',
      contentJson: '',
      showLoading: false,
      todayNumber: ''
    }
  },
  created() {
    // console.log(this.$route.params)
    let info = this.$route.params
    if (info.id) {
      this.orderId = info.id
      this.title = info.title
      localStorage.setItem('orderId', this.orderId)
      localStorage.setItem('rewriteTitle', this.title)
      this.getRewriteJson(this.orderId)
      this.getRewriteLimit()
    } else {
      if (localStorage.getItem('orderId')) {
        this.orderId = localStorage.getItem('orderId')
        this.title = localStorage.getItem('rewriteTitle')
        this.getRewriteJson(this.orderId)
        this.getRewriteLimit()
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem('orderId')
    localStorage.removeItem('rewriteTitle')
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
    goBack() {
      this.$router.go(-1)
    },
    lookItem() {
      if (this.showLoading === true) return
      this.showLoading = true
      // 导出，按原格式提交修改后的数据
      // console.log('提交修改=', this.contentJson)
      let data = {
        contentJson: this.contentJson,
        orderId: this.orderId
      }
      this.postRewriteSave(data)
    },
    modifyContent(msg) {
      // 接收组件修改后的值
      this.contentJson[msg.listIndex].content = msg.list
      // console.log('修改后=', this.contentJson)
    },
    getTodayNumber(msg) {
      if (!msg) return
      this.getRewriteLimit()
    },
    scrollToAnchor(anchor) {
      // 锚点跳转
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor)
        // if (anchorElement) {
        //   anchorElement.scrollIntoView({ behavior: 'smooth' });
        // }
        // 确保元素存在
        if (!anchorElement) return
        // 计算元素距离页面顶部的距离
        const elementTop = anchorElement.getBoundingClientRect().top + window.pageYOffset - 80
        // 滚动到目标位置
        window.scrollTo({
          top: elementTop,
          behavior: 'smooth'
        })
      })
    },
    getRewriteJson(id) {
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      RewriteApi.getRewriteJson(id, key, time, infoMd5).then(res => {
        _this.orderId = res.data.orderId
        _this.contentJson = res.data.contentJson
      })
    },
    postRewriteSave(data) {
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      RewriteApi.postRewriteSave(data, key, time, infoMd5).then(res => {
        // console.log(res.data)
        _this.$message.success('导出成功')
        _this.showLoading = false
        window.location.href = res.data
      })
    },
    getRewriteLimit() {
      RewriteApi.getRewriteLimit(this.orderId).then(res => {
        // console.log(res.data)
        this.todayNumber = res.data
      })
    },
    // test1() {
    //   // let temp = JSON.parse(jsonInfo.value)
    //   let temp = JSON.parse(this.contentJson)
    //   for (let i = 0; i < temp.length; i++) {
    //     let obj = temp[i]
    //     let first = this.getFirstTitle(obj)
    //     if (first) {
    //       console.log(first)
    //     }
    //     let second = this.getSecondTitle(obj)
    //     if (second) {
    //       console.log(second)
    //     }
    //     let three = this.getThreeTitle(obj)
    //     if (three) {
    //       console.log(three)
    //     }
    //   }
    // },
    getFirstTitle(point) {
      let temp
      if (point.level === 3 && point.secondNo && point.secondNo === 1 && point.threeNo && point.threeNo === 1) {
        temp = point.firstTitle
      } else if (point.level === 2 && point.secondNo && point.secondNo === 1) {
        temp = point.firstTitle
      } else if (point.level === 1) {
        temp = point.firstTitle
      }
      if (!temp) {
        temp = ""
      }
      return temp
    },
    getSecondTitle(point) {
      let temp
      if (point.level === 3 && point.threeNo && point.threeNo === 1) {
        temp = point.secondTitle
      } else if (point.level === 2) {
        temp = point.secondTitle
      }
      if (!temp) {
        temp = ""
      }
      return temp
    },
    getThreeTitle(point) {
      let temp
      if (point.level === 3) {
        temp = point.threeTitle
      }
      if (!temp) {
        temp = ""
      }
      return temp
    }
  }
}
</script>
<style scoped lang="less">
.ai-box {
  width: 100%;
  min-height: 100%;
  background-color: #f4f4f4;

  .ai-header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 85, 255, 0.15);

    .logo-img {
      position: absolute;
      left: 0;
      top: 0;
      width: 300px;
      height: 60px;
      padding-left: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      img {
        width: auto;
        height: 84%;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        color: #101010;
      }
    }

    .today-num {
      position: absolute;
      right: 240px;
      top: 0;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .ai-header-btn {
      position: absolute;
      right: 20px;
      top: 0;
      width: 200px;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .pc-show-btn{
      display: flex;
    }

    .h5-show-btn{
      display: none;
    }

    .ai-header-title {
      line-height: 60px;
      color: #6c6c6c;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
      }

      h1 {
        font-size: 14px;
        font-weight: initial;
      }
    }
  }

  .ai-main {
    width: 100%;
    padding: 0;

    .ai-main-box {
      min-height: 100vh;
      position: relative;
      padding: 30px 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .ai-directory {
        position: fixed;
        left: 0;
        top: 80px;
        z-index: 99;

        .ck-btn {
          position: relative;
          width: 40px;
          height: 60px;
          border-radius: 0 10px 10px 0;
          background-color: #ffffff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;

          span {
            display: block;
            padding: 10px 0 0 12px;
            font-size: 14px;
          }

          .ck-cont {
            position: absolute;
            top: 0;
            left: 38px;
            width: 300px;
            padding: 20px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .mu-lu-l1 {
              margin-bottom: 8px;

              h2 {
                margin-bottom: 8px;
              }

              .mu-lu-l2 {
                padding-left: 14px;
                margin-bottom: 6px;

                h3 {
                  font-weight: initial;
                  color: #666666;
                }

                h3:hover {
                  text-decoration: underline;
                }

                .mu-lu-l3{
                  margin: 8px 0 0 12px;
                  h4 {
                    font-weight: initial;
                    color: #666666;
                  }

                  h4:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }

          .show-ck {
            display: none;
          }
        }

        .ck-btn:hover {
          .show-ck {
            display: block;
          }
        }
      }

      .ai-cont {
        width: 100%;
        max-width: 750px;

        .ai-cont-list {
          padding: 0 20px 20px 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #dcdcdc;

          h2 {
            margin-bottom: 15px;
            font-size: 16px;
            color: #323232;
          }
          h3 {
            margin-bottom: 10px;
            color: #4a4a4a;
            font-weight: initial;
          }
          h4 {
            margin-bottom: 10px;
            color: #4a4a4a;
            font-weight: initial;
          }

          //.ai-cont-item {
          //  padding-bottom: 20px;
          //  margin-bottom: 20px;
          //  border-bottom: 1px solid #dcdcdc;
          //
          //  h3 {
          //    margin-bottom: 10px;
          //    color: #4a4a4a;
          //    font-weight: initial;
          //  }
          //}
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .el-header{
    height: 100px !important;
  }
  .ai-box {
    .ai-header {
      height: 100px;
      display: inline;
      .logo-img{
        position: initial;
        width: 100%;
        height: 34px;
        margin-top: 5px;
        justify-content: center;
        span {
          font-size: 14px;
          font-weight: initial;
          color: #606060;
        }
      }
      .ai-header-title{
        position: absolute;
        top: 36px;
        width: 100%;
        height: 32px;
        span{
          font-weight: bold;
          color: #3c3c3c;
        }
        h1{
          font-weight: bold;
          color: #3c3c3c;
          line-height: 16px;
          max-width: 70%;
          text-align: left;
        }
      }
      .today-num{
        width: 100%;
        left: 0;
        top: 50px;
        padding: 0 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 10px;
        }
      }
      .pc-show-btn{
        display: none;
      }
      .h5-show-btn{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        padding: 10px 10px 0 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .el-button{
          padding: 5px 8px 5px 5px;
        }
      }
    }
    .ai-main{
      .ai-main-box{
        .ai-directory{
          top: 120px;
          .ck-btn{
            .ck-cont{
              width: 240px;
              max-height: 70vh;
              overflow: auto;
              .mu-lu-l1 {
                h2 {
                  font-size: 11px;
                }
                .mu-lu-l2 {
                  h3 {
                    font-size: 11px;
                  }
                  .mu-lu-l3{
                    h4 {
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
